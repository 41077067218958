@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Inter&family=Lato&family=Montserrat&family=Pacifico&display=swap");

:root {
  --primary: #3e79f7 !important;
  --primary-two: #0000fb44 !important;
  --primary-three: #5353fba1 !important;
  --text: #72849a !important;
  --active-list: #455560 !important;
  --danger-alt: #ff6b72 !important;
  --secondary: #356396 !important;
  --light: #3e79f718 !important;
  --accent: #5353fba1 !important;
}

a:hover {
  text-decoration: none;
}

/* custom modifaed theme */

.scrollbar_smooth {
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.scrollbar_smooth::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.scrollbar_smooth::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.scrollbar_smooth::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

.bg-custom-1 {
  background-color: #eaf4fe !important;
  transition: all 0.3s ease;
}
.bg-primary {
  background-color: var(--primary);
}
.ant-btn-primary {
  background-color: var(--primary);
}
.ant-btn-primary:not(:disabled):hover {
  background-color: var(--secondary);
}
.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--primary);
}
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ),
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: var(--primary);
}
.ant-menu-light .ant-menu-item-selected {
  color: var(--primary);
}
.ant-menu-light .ant-menu-item-selected {
  background-color: var(--light);
}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: var(--light);
}
.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--light);
}
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after {
  border-color: var(--primary);
}
.ant-spin .ant-spin-dot-item {
  background-color: var(--primary) !important;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--light);
}
.ant-select-focused .ant-select-selector,
.ant-select-open.ant-select-show-search .ant-select-selector {
  border: 1px solid var(--primary) !important;
}
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: var(--primary) !important;
}
h5.input_img_text > span.text-info {
  color: var(--primary) !important;
}
.ant-spin {
  color: var(--primary);
}
.border-button:hover,
.edit-button:hover {
  color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--primary);
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--primary);
}
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary) !important;
}
.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary) !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-focused {
  box-shadow: 0 0 0 2px var(--light);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--primary);
}
.ant-checkbox-checked:after {
  border-color: var(--primary);
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: var(--light);
}
.ant-breadcrumb a:hover {
  color: var(--primary);
}
.ant-pagination .ant-pagination-item-active {
  background-color: var(--primary);
}
.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-wrapper .ant-radio-checked::after {
  border-color: var(--primary) !important;
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--primary);
}
.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover {
  color: var(--hover);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: var(--accent);
}
.ant-picker-focused,
.ant-picker:hover {
  border-color: var(--primary);
}
.ant-picker-now-btn {
  color: var(--primary);
}

/* --------Global Css========> */

ul {
  padding: 0;
}
ul li {
  list-style-type: none;
}

input::placeholder {
  color: #b8b8b89e !important;
}
/* new class css */
.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-5 {
  gap: 20px;
}

.left-0 {
  left: 0;
}
.top-0 {
  top: 0;
}
.shadow-sm {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
}
.show {
  display: flex;
}
.hide {
  visibility: hidden !important;
  opacity: 0;
  transition: all 0.3s ease;
}

.scrollbar_smooth.scrollbar_smooth_Y {
  overflow-y: scroll;
  overflow-x: hidden;
}
.scrollbar_smooth.scrollbar_smooth_x {
  overflow-y: hidden;
  overflow-x: scroll;
}

.scrollbar_smooth::-webkit-scrollbar {
  width: 2px;
}
.scrollbar_smooth::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}

/* ---------------------- */

.site_logo {
  object-fit: contain;
}

/* UPLOAD IMAGE COMPONENT */
.file_upload_sec {
  border: 1px dashed gray;
  cursor: pointer;
  width: 300px;
  aspect-ratio: 1/1;
  /* height: 300px; */
  margin: 0 auto;
  border-radius: 50% !important;
  text-align: center;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right {
  width: 50px;
  height: 50px;
  display: flex;
}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right {
  filter: grayscale(1);
  opacity: 0.6;
}

.file_upload_sec .img_icon_left {
  right: -50px;
  transition: all 0.3s ease;
}
.file_upload_sec .img_icon_right {
  left: -50px;
  transition: all 0.3s ease;
}
.file_upload_sec:hover .img_icon_left {
  right: -20px;
  transform: rotate(-20deg);
  transition: all 0.3s ease;
}
.file_upload_sec:hover .img_icon_right {
  left: -20px;
  transform: rotate(20deg);
  transition: all 0.3s ease;
}

.file_upload_sec .img_icon {
  z-index: 1;
}
.file_upload_sec .img_icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.file_upload_sec .uploaded_img {
  object-fit: cover;
  object-position: center;
}

.file_upload_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.img_delete_sec {
  top: 0;
  left: 0;
  background-color: rgba(174, 173, 173, 0.41);
}
.delete_btn {
  border: 1px solid #ff6b72;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ff6b72;
  transition: all 0.3s ease;
}
.delete_btn:hover {
  scale: 1.2;
  transition: all 0.3s ease;
}
/* =====================> */

.tasks_card {
  background-color: #fafafb;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
}
.tasks_card .img_sec {
  width: 45%;
  overflow: hidden;
  border-radius: 5px;
}
.tasks_card .list_sec {
  width: -webkit-fill-available;
}
.tasks_card .img_sec img {
  object-fit: cover;
  object-position: center;
}
.tasks_card .challenge_card > div {
  height: 136px;
}
.tasks_card .challenge_card .list_sec {
  overflow-y: scroll;
}
/* .tasks_card::before{content: '';width: 80px;height: 80px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-three);}
.tasks_card::after{content: '';width: 90px;height: 90px;position: absolute;bottom: -36px;right: -36px;border-radius: 50%;display: flex;background-color: var(--primary-two);z-index: 1;} */

.tasks_card .checkbox {
  border: 2px solid transparent;
  width: 18px;
  height: 18px;
  border-radius: 3px;
}
.tasks_card .checkbox.checked {
  background-color: var(--primary);
}
.tasks_card .checkbox:not(.checked) {
  border-color: var(--primary);
  background-color: transparent;
}
.tasks_card .checkbox > span {
  scale: 0.8;
}
.tasks_card li .challenge_name {
  color: var(--text);
}
.tasks_card .challenge_card_heading {
  color: var(--primary);
}
.quill > .ql-container {
  border-radius: 0 0 10px 10px;
  height: 200px;
  max-height: 250px;
}

/* <---------media box------> */
.media_box {
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  margin: 10px;
}
.media_box img {
  object-fit: cover;
  object-position: center;
}
.media_box.pdf_sec .icon img {
  object-fit: contain;
  object-position: center;
}
.media_box .name_sec {
  background-color: var(--primary);
  color: white;
  overflow: hidden;
  white-space: nowrap;
}
.media_box .name {
  overflow: hidden;
}

/* select custom components */

.select_custom {
  position: relative;
}
.select_custom .list_sec {
  position: absolute;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin-top: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  z-index: 2;
  max-height: 240px;
  overflow-y: scroll;
}
.select_custom .list_sec::-webkit-scrollbar {
  width: 8px;
}
.select_custom .list_sec::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.select_custom .list {
  cursor: pointer;
  background-color: transparent;
  margin: 4px 0;
}
.select_custom .list:hover {
  background-color: #fafafb;
}
.select_custom .checked_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.select_custom .dropdown .selected {
  background-color: #95b7ff13;
}

/* ----delete popup---- */

.delete_popup {
  background-color: rgba(0, 0, 0, 0.255);
  z-index: 1000;
}
.delete_popup .popup {
  border-radius: 5px;
  width: 300px;
}
.delete_popup .popup .dialogue {
  line-height: 20px;
}
.delete_popup .popup .del_btn {
  background-color: var(--danger-alt);
}
.delete_popup .popup .del_btn:focus-visible {
  outline: 4px solid #ff848ab1;
}
.delete_popup .popup .del_btn:hover {
  border: 1px solid #ff757c !important;
}

/* full screen loading-----> */
.full_screen_loading {
  z-index: 1000;
}

/* coach_profile_userlist */

.coach_profile_userlist {
  height: 325px;
}

/* media library */

.media_container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.card-box {
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: #fff;
}

.file-man-box {
  overflow: hidden;
  padding: 10px;
  border: 1px solid #e3eaef;
  border-radius: 5px;
  position: relative;
  height: 144px;
  max-width: 144px;
  min-width: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.file-man-box .file-img-box {
  text-align: center;
}
.file-man-box .file-img-box img {
  height: 64px;
}

.file-man-box:hover {
  -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
}
.file-man-box h5 {
  font-size: 13px;
  margin-bottom: -8px;
}
.file-man-box.affirmation {
  padding: 0;
  overflow: hidden;
}
.file-man-box.affirmation .file-img-box,
.file-man-box.affirmation .file-img-box img {
  width: 100%;
  height: 65%;
}
.file-man-box.affirmation .file-img-box.image,
.file-man-box.affirmation .file-img-box.image img {
  width: 100%;
  height: 100%;
}
.file-man-box.affirmation .file-img-box img {
  object-fit: contain;
  object-position: center;
}
.file-man-box.affirmation .file-img-box.image img {
  object-fit: cover;
  object-position: center;
}

.file-man-box .action {
  background-color: #00000070;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
.file-man-box:hover .action {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.file-man-box .action > div {
  height: 35px;
  width: 35px;
  background-color: #fafafb;
  border-radius: 3px;
}
.file-man-box .action > div.del {
  background-color: var(--danger-alt);
  color: white;
}
.file-man-box .action > div:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.file-man-box .check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: white;
}
.media_header .ant-upload-list {
  display: none;
}
.uploading_box {
  border: 1px dashed rgba(128, 128, 128, 0.585);
}
.uploading_text {
  color: gray;
  font-size: 12px;
}

/* view media */
.media_drawer .ant-drawer-body {
  padding: 12px !important;
}
.media_draw_box .media_draw_box_preview {
  width: 100%;
  overflow: hidden;
  height: 150px;
  border-radius: 5px;
}
.media_draw_box .media_draw_box_preview img {
  object-fit: cover;
  object-position: center;
}
.media_draw_box .details_sec table div {
  padding: 3px 0;
  color: gray;
}
.media_draw_box .details_sec table tr td:last-child div {
  padding-left: 30px;
  color: var(--primary);
}

.media_header_sec .check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  background-color: white;
}

/* checkbox */

.check_box {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt {
  border: 2px solid #e6ebf1;
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt:hover {
  border: 2px solid var(--primary);
  width: 20px;
  height: 20px !important;
  border-radius: 3px;
  top: 3px;
  right: 3px;
  background-color: white;
}
.check_box_alt.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

/* Query Search Filter */
.table-filter-list {
  padding: 7px 20px;
  border-radius: 10px;
}
.table-filter-list:hover {
  background-color: #f0f7ff;
}
.filter_list_sec .filter_list {
  max-height: 165px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(128, 128, 128, 0.311);
}
.filter_list_sec .filter_list::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb {
  width: 100%;
  background-color: var(--primary);
  border-radius: 5px;
}
.filter_list_sec .filter_list::-webkit-scrollbar-corner {
  background-color: transparent;
  color: transparent;
}

/* Active Inactive button */

.activeBtn {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.activeBtn:hover {
  color: #08979c !important;
  background: #e6fffb !important;
  border-color: #87e8de !important;
}
.inActiveBtn {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.inActiveBtn:hover {
  background: #fff2e8 !important;
  color: #d4380d !important;
}

.multi-datepicker .rmdp-container,
.multi-datepicker .rmdp-container input {
  width: 100%;
}

/* player */
.audio-player {
  background-color: white;
  border-radius: 10px;
  width: 350px;
}
.skip_prev {
  transform: rotate(90deg);
}
.skip_next {
  transform: rotate(-90deg);
}
.skip_prev::before,
.skip_next::before {
  content: "10";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8px;
}
.skip_prev::before {
  transform: rotate(-90deg);
}
.skip_next::before {
  transform: rotate(90deg);
}
.skip_prev,
.skip_next,
.play_pause,
.next,
.prev {
  width: 50px;
  height: 50px;
  font-size: 25px;
  position: relative;
  background-color: #f3f5f9;
  border-radius: 50%;
  cursor: pointer;
}
.skip_prev,
.skip_next {
  width: 35px;
  height: 35px;
  font-size: 20px;
}
.progress_bar .ant-progress-outer * {
  height: 4px;
}
.progress_bar_sec {
  width: 100%;
  height: 15px;
}
.progress_bar_sec > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.progress_bar_sec span.progress {
  top: 3px;
  opacity: 0;
}
.progress_bar .ant-progress-success-bg {
  height: 4px !important;
  cursor: pointer;
}
.progress_group {
  height: 12px;
}
.duration_left,
.total_duration {
  font-size: 10px;
}
.audio_name {
  background-color: #eaf4fe;
  border-radius: 5px;
}
.audio_name .name {
  width: 235px;
  overflow: hidden;
}
.audio_name .name > div {
  white-space: nowrap;
  font-weight: 600;
  font-size: 17px;
}
.audio_list .icon {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio_list .audio_item {
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.audio_list .audio_item.active {
  background-color: white !important;
}
.audio_list .audio_item .name {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.audio_list_sec {
  max-height: 230px;
}
.audio_list_sec .audio_item:hover .name > div {
  -moz-animation: marquee 15s linear infinite;
  -webkit-animation: marquee 15s linear infinite;
  animation: marquee 15s linear infinite;
}
.timer_duration {
  margin-top: 5px;
}
.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", sans-serif;
  content: "Roboto" !important;
}

/* .ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  content: "Raleway" !important;
} */

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", sans-serif;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", sans-serif;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", sans-serif;
  content: "Rubik" !important;
}
.ql-picker.ql-font .ql-picker-label[data-value="Pacifico"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Pacifico"]::before {
  font-family: "Pacifico", cursive;
  content: "Pacifico" !important;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto";
}
.ql-font-Raleway {
  font-family: "Raleway";
}
.ql-font-Montserrat {
  font-family: "Montserrat", sans-serif;
}
.ql-font-Lato {
  font-family: "Lato", sans-serif;
}
.ql-font-Rubik {
  font-family: "Rubik", sans-serif;
}
.ql-font-Inter {
  font-family: "Inter", sans-serif;
}
.ql-font-Pacifico {
  font-family: "Pacifico", cursive;
}

.trigger-scroll {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 5px;
  background-color: var(--primary);
  justify-content: center;
  align-items: center;
}
.trigger-scroll a {
  color: white;
  width: 100%;
  height: 100%;
}

.form_label_certifications {
  position: relative;
  align-items: center;
  background: rgba(19, 139, 251, 0.05);
  border: 1px dashed rgb(13, 87, 245);
  border-radius: 12px;
  color: rgb(13, 87, 245);
  display: inline-flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  line-height: 23px;
  min-height: 60px;
  width: 100%;
  padding: 12px 24px;
}

#uploadFile {
  height: 1px;
  left: -999px;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  width: 1px;
}
.preview {
  position: relative;
  align-items: center;
  max-width: 100%;
  height: 40px;
  color: #455560;
  font-size: 14px;
  margin-bottom: 0;
}
.booking {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 400px;
  height: 500px;
  /* overflow: auto; */
}
.booking-lists {
  width: 100%;
  max-height: 300px;
  padding: 20px;
  overflow: auto;
}

.media-selector {
  border: 1px dashed rgb(211, 211, 211);
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
}
.media-selector input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
}
.media-selector .media {
  top: 0;
  left: 0;
}
.media-selector .media .thumbnai {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  border: 1px dashed rgb(211, 211, 211);
  overflow: hidden;
  padding: 2px;
}
.media-selector .media .thumbnai img {
  border-radius: 3px;
}
.media-selector .filename {
  height: 50px;
  width: calc(97% - 50px);
  border-radius: 3px;
}
.media-selector .filename .name {
  width: calc(99% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgb(185, 185, 185);
}
.media-selector .close-btn {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: var(--danger-alt);
  color: white;
  font-size: 12px;
  z-index: 3;
}
.upload-media-modal .radio-item {
  border-radius: 5px;
  white-space: nowrap;
  background-color: var(--light);
}
.number-count-animation {
  font-size: "25px";
}

/* / ---------------Scrollbar------------------------ / */
/* / For WebKit Browsers (Chrome, Safari) / */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* / For Firefox / */
.scrollBar html {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) #f1f1f1;
}
.custom-scrollbar {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: auto;
  padding-right: 10px;
  width: 100%;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #afafaf;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}
.items {
  align-items: start !important;
}

.tableclrchange thead tr th {
  background-color: rgb(86 77 245 / 10%) !important;
  color: #564df5 !important;
}
.ant-table-wrapper .ant-table-column-sorter {
  color: #564df5;
}
.ant-table-wrapper .ant-table-filter-trigger:hover {
  color: rgb(56 46 239);
}
.table-responsive.tableclrchange {
  padding: 0 18px 18px;
}
.css-7prgfd {
  padding: 9px 1rem !important;
}
.ant-table-wrapper .ant-table-filter-trigger {
  color: rgb(111 104 241);
}
.side-nav .ant-menu-light {
  color: #ffffff !important;
  background: #011032 !important;
}
.ant-menu-light .ant-menu-sub .ant-menu-item-selected {
  background-color: #1c57d5 !important;
  color: #fff !important;
  border-radius: 5px;
  height: auto;
}
/* .abc{background-color: #f00 !important; height: 500px !important; } */
/* .ant-menu-light .ant-menu-item-selected, .ant-menu-light>.ant-menu .ant-menu-item-selected{ background: #3e79f7 !important; color: white !important;} */
.ant-menu {
  padding: 17px;
}
li.ant-menu-submenu.ant-menu-submenu-vertical {
  margin: 38px 0;
}
li.ant-menu-submenu.ant-menu-submenu-inline {
  margin: 30px 0;
}
li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
  background-color: #e8e7ff;
  border-radius: 5px;
  height: auto;
}
.page-heading-font {
  position: relative;
  color: #000;
  font-weight: bolder !important;
  font-size: 27px;
}
.css-1a4gf49 {
  background: rgb(241 245 249);
}
.ant-card.boxes {
  width: 200px;
  padding: 6px;
  border-radius: 8px !important;
  margin: 0;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  transition: all 0.3s ease;
  background: #f0efff;
  border: 1px solid #a9c6ff;
  height: 100%;
}
.ant-card.boxes .ant-card-head-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  text-align: right;
  color: #5a5a5a;
}
.ant-card.boxes .ant-card-body span {
  text-align: right;
  display: block;
  font-size: 26px;
  font-weight: bold;
}
.ant-card.boxes .ant-card-body {
  padding-bottom: 6px;
  box-shadow: none;
}
.ant-layout-content > div {
  padding-left: 0 !important;
}
.ant-card.boxes:hover {
  box-shadow: 0 4px 8px rgba(23, 17, 562, 0.4);
}
.ant-card-body .boxesicon {
  position: absolute;
  top: 12px;
  background: #1c57d5;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
  left: 10px;
}
.ant-card.boxes .ant-card-body .boxesicon span {
  text-align: center;
  margin: 8px auto 0;
  font-size: 22px;
}
.ant-card .ant-card-head {
  padding: 0 9px;
}
.barchart {
  display: block;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.active-driver-width {
  width: 35%;
}
.dashboardactivedriver .ant-card-head-title {
  color: #000;
  font-weight: bolder !important;
  font-size: 27px;
  padding-left: 15px;
}
.table
  .dashboardactivedriver
  :where(.css-dev-only-do-not-override-1u35f9t).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  background-color: rgb(86 77 245 / 10%) !important;
  color: #564df5 !important;
  border: 0.5px solid transparent !important;
  border-top: 0.5px solid transparent !important;
}
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: rgb(86 77 245 / 10%) !important;
}
.ant-table-thead > tr {
  background-color: rgb(86 77 245 / 10%) !important;
  color: #564df5 !important;
  border: 0.5px solid transparent !important;
  border-top: 0.5px solid transparent !important;
}
.ant-table-row .table td,
.table th {
  border-top: 0 !important;
}
/* .table td, .table th{border: 0!important;} */
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-1u35f9t).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent !important;
}
/* .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td{background-color: transparent!important;} */
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0 !important;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-size: 14px !important;
}
.table td,
.table th {
  border-top: 1px solid #dee2e600;
}

:where(.css-1u35f9t)[class^="ant-input"] [class^="ant-input"],
:where(.css-1u35f9t)[class*=" ant-input"] [class^="ant-input"],
:where(.css-1u35f9t)[class^="ant-input"] [class*=" ant-input"],
:where(.css-1u35f9t)[class*=" ant-input"] [class*=" ant-input"] {
  box-sizing: border-box;
  padding: 4px !important;
}

.ant-input-affix-wrapper {
  padding: 4px 11px !important;
  border-radius: 6px !important;
}
.ant-input-affix-wrapper:hover {
  padding: 0 !important;
}
.ant-input-affix-wrapper .ant-input {
  padding: 5px 11px !important;
}

.dashboardactivedriver .ant-table-tbody {
  border: 1px solid #f1f1f1;
}
.ant-table-wrapper table {
  border-radius: 12px 12px 6px 6px;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
}
.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: transparent;
}
.dashboardrecentbooking {
  width: 64%;
}
.dashboardrecentbooking .ant-card {
  margin-bottom: 0;
}
.dashboardrecentbooking .ant-card-head-title {
  color: #000;
  font-weight: bolder !important;
  font-size: 27px;
  padding-left: 15px;
}
.ant-layout .ant-layout-sider .ant-layout-sider-children {
  background: #011032 !important;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: rgb(86 77 245 / 10%) !important;
  color: #564df5 !important;
}
.ant-card-body .css-zhbgj8 {
  align-items: start;
  -webkit-align-items: start;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #dfdfdf;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #000;
}
.ant-input-affix-wrapper {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  padding: 9px 13px;
}
.ant-select .ant-select-arrow {
  color: #000;
}
.table-responsive {
  padding: 0 16px 18px;
}
/* .ant-table-wrapper .ant-table-tbody >tr >td{border-bottom: 1px solid #f3f3f3 !important;} */
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-1u35f9t).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: #64646400;
}
.ant-input::placeholder {
  color: #000 !important;
}
.ant-menu-light.ant-menu-inline .ant-menu-item,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-item,
.ant-menu-light.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 !important;
  padding-left: 10px !important;
}
.ant-menu .ant-menu-item .ant-menu-item-icon,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu .ant-menu-item .anticon,
.ant-menu .ant-menu-submenu-title .anticon {
  font-size: 19px;
}
.ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
  background: #fff;
  border-radius: 5px;
  color: #564df5;
}
.ant-menu-light
  .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  background: #fff;
  border-radius: 5px;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  background: #fff;
  border-radius: 5px;
}
.css-1a4gf49 .ant-form {
  background: #fff;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 0;
}
.ant-form-item .ant-form-item-label > label {
  color: #717d85 !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.ant-input {
  padding: 10px 11px;
  border-radius: 6px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
  padding: 10px 11px;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  padding: 4px 11px !important;
  border-radius: 6px !important;
}
.ant-input {
  padding: 11px 11px !important;
  border-radius: 6px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 20px 11px;
  border-radius: 6px;
}
.ant-select-selection-placeholder {
  color: #000 !important;
}
.contact-table .ant-table-wrapper table tr {
  border-bottom: 1px solid #f1f1f1;
}
.ant-table-tbody tr:not(:last-child) {
  border-bottom: 1px solid #dddddd !important;
}
.ant-layout-sider-children > div > div {
  overflow-x: auto !important;
}
.enquiy-detail-top-part {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 30px;
}
.enquiry-content-part {
  border-right: 1px solid grey;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}
.messagebox-wrapper {
  height: 70vh;
  overflow: auto;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.message-btn {
  margin: 0 0 20px 0;
}
.admintitle {
  color: #000;
  font-weight: bolder !important;
  font-size: 27px;
  margin: 0;
  /* border-bottom: 1px solid rgb(224, 224, 224); */
  padding-bottom: 12px;
  align-items: center;
}
.admintitle .modal-close-btn {
  background: white !important;
  border: 1px solid gray !important;
  border-radius: 80px !important;
  color: gray !important;
  width: 30px !important;
  height: 32px !important;
  /* height: 30px !important; */
  align-items: center !important;
  justify-content: center !important;
  transition: 0.5s all ease;
  display: flex;
}
.admintitle .modal-close-btn:hover {
  color: #08c !important;
  border-color: #3e79f7 !important;
  rotate: 90deg;
}
.edit-admin .css-k008qs {
  align-items: center;
}
.edit-driver-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 40px;
  /* padding-left: 15px; */
}
.edit-discardbtn .discardbtn {
  background: #3e79f7;
  color: #fff;
  border-radius: 6px;
}
.edit-discardbtn .discardbtn:hover {
  color: #fff !important;
  border: none;
}
.edit-drivertabs .ant-tabs-tab {
  background: #f1f1f1;
  margin: 0 8px 0 0 !important;
  padding: 11px 16px;
  border-radius: 10px 10px 0 0;
}
.edit-drivertabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #8680f9;
  color: #fff !important;
}
.edit-drivertabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.edit-driversavebutton {
  display: flex;
  justify-content: right;
  margin: 20px 0 0;
}
.driverinfotab {
  display: flex;
  justify-content: space-between;
}
.driverinfotitle .ant-card-head-title {
  font-size: 19px;
  color: #000;
}
.drivertabwrapper {
  padding-left: 16px;
}
.license-number {
  width: 100%;
}
.licensegallery {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.driverlicensebtn {
  display: flex;
  justify-content: right;
}
.tlclicensebtn {
  display: flex;
  justify-content: right;
}
.tlcfrontgallery {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.tlcinput {
  display: flex;
  align-items: center;
  width: 100%;
}
.tlcinput .form-group {
  width: 50%;
  gap: 2px;
  margin-right: 12px;
}
.tlcinput .tlcdateinput {
  border-radius: 6px;
  padding: 10px 11px;
}
.form-inline {
  width: 100%;
}
.insurancetabwrapper {
  display: flex;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
}
.insurancetabwrapper .form-group {
  width: 32%;
}
.insurancetabwrapper .form-group .ant-form-item-row {
  width: 100%;
}
.insurancetabwrapper .insurancedatepicker {
  border-radius: 6px;
  padding: 10px 11px;
}
.insurancegallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin: 20px 0 0;
}
.drugtestbtn {
  display: flex;
  justify-content: right;
}
.drugtestdatepicker {
  border-radius: 6px;
  padding: 10px 11px;
}
.fhvlicensetab {
  display: flex;
  justify-content: right;
}
.fhvgallery {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  margin: 20px 0 0;
}
.vehicleinformationbtn {
  display: flex;
  justify-content: right;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.vehicle-information-grid .ant-flex {
  display: contents;
}
.driver-detail-form {
  opacity: 0;
}
button:focus {
  outline: 0;
}
.driver-details {
  background: #f4f3ff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  margin: 0 0 28px;
  display: inline-block;
}
.driver-avatar .ant-avatar {
  width: 100%;
  border-radius: 6px;
  height: 340px;
  border: 2px solid #8680f9;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  margin: 17px 0 0;
}
.driver-details-wrapper .ant-card-body {
  gap: 20px;
  padding: 27px;
}
.review-headtext {
  color: #3e79f7;
}
.transactiontable {
  width: 100%;
  border: none;
}
.transactiontable .table-responsive {
  padding: 0;
}
.transactiontable .ant-card-body {
  padding: 0px;
  border: none;
  gap: 0;
}
.drivertab .ant-tabs-tab {
  background: #f1f1f1;
  margin: 0 8px 0 0 !important;
  padding: 11px 16px;
  border-radius: 10px 10px 0 0;
  font-size: 14px;
}
.drivertab .ant-tabs-tab.ant-tabs-tab-active {
  background: #8680f9;
}
.drivertab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
.driver-avatar .avatar-status {
  justify-content: left;
}
/* .avatar-status .ml-2 {
  margin-left: 0 !important;
} */
.profile-edit-btn {
  justify-content: end!important;
  width: 100%;
}
.avatar-name .mail {
  display: flex;
  justify-content: left;
}
.avatar-name .media-body {
  margin: 15px 0 0;
}
.avatar-name .mail p {
  line-height: 0;
  margin: 0 0 21px;
}
.verify-card {
  background: #fff;
  width: 100%;
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.verify-card .email-verify > div p,
.verify-card .status-verify > div p,
.verify-card .online-status > div p {
  display: flex;
  justify-content: space-between;
  color: #8680f9 !important;
  font-weight: 500;
}
.verify-card .email-verify,
.status-verify {
  margin: 0;
}
.info-display .city-info {
  text-align: left;
}
.avatar-name .media-heading {
  text-transform: capitalize;
  text-align: centeleftr;
}
.info-display .city-name {
  text-align: left;
}
.info-display .city-icon {
  border: 1px solid #d1d1d1;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding: 6px;
  color: #756ef5 !important;
}
.info-display .city-info span {
  font-size: 17px;
  color: #545454 !important;
  font-weight: 500;
}
.info-display .ant-row {
  align-items: center;
  justify-content: space-between;
}
.info-display .city-name .ant-tag {
  margin: 0;
}
.driverdetailtabpart {
  width: 100%;
  overflow: hidden;
}
.vehicle-table {
  padding: 21px;
}
.vehicle-table h2 {
  color: #000;
  font-weight: bolder !important;
  font-size: 20px;
  margin: 0 0 20px;
}
.ant-card-head-wrapper .ant-card-head-title {
  color: #000;
  font-weight: bolder !important;
  font-size: 27px;
  margin: 0 0 0;
  padding-left: 14px;
}
.drivertab {
  margin-top: 0 !important;
}
.licensetab .ant-card-body {
  flex-direction: column;
  padding: 20px 21px 0;
  gap: 0;
}
.license-nmbr-area {
  display: flex;
  align-items: center;
}
.license-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px;
}
.licensenmbrinfo {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
}
.license-gallery {
  padding: 20px 21px 20px;
}
/* .portfolioImage{height: 300px !important;} */
.license-information .ant-modal-content {
  padding: 0;
  background: transparent;
  overflow: hidden;
}
.license-information .ant-modal-header {
  background: #8680f9;
  padding: 18px 22px;
  margin: 0;
}
.license-information .ant-modal-title {
  color: #fff !important;
}
.license-information .ant-modal-close {
  color: #fff !important;
  transition: all 0.3s ease;
}
.license-information .ant-modal-close:hover {
  transform: rotate(90deg);
  transition: all 0.3s ease;
}
.license-information .ant-modal-body {
  background: #fff;
  padding: 15px 0;
}
.license-information .ant-modal-footer {
  background: #fff;
  padding: 15px 16px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f1f1f1;
}
.license-information .ant-card {
  margin: 0;
}
.license-information .ant-form-item {
  margin: 0;
}
.license-information .ant-modal-footer .ant-btn-default {
  background: #ededed;
}
.driver-avatar {
  width: 20%;
}
.avatar-detail {
  width: 89%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  max-height: 70px;
}
.driver-detail-wrapper {
  display: flex;
  gap: 30px;
}
.verify-portion-wrapper {
  width: 80%;
  margin: -207px 0 0;
  float: right;
}
.info-display {
  width: 100%;
  margin: -58px 0 0;
  column-count: 2;
  gap: 100px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 24px;
}
.verify-card .ant-tag {
  line-height: 26px;
  margin: 0 0 0 5px;
}
span.anticon.anticon-border-outer {
  color: #f43f5e;
}
.ant-menu-title-content span.anticon.anticon-check-square {
  color: #eab308;
}
.ant-menu-title-content span.anticon.anticon-car {
  color: #22c55e;
}
.ant-menu-title-content span.anticon.anticon-safety-certificate {
  color: #f59e0b;
}
span.anticon.anticon-container {
  color: #d946ef;
}
.ant-menu-title-content > span {
  text-transform: uppercase;
}
.ant-menu.ant-menu-sub .ant-menu-title-content span {
  text-transform: capitalize;
}
.rolemodal {
  padding: 0;
}
.ant-menu-title-content span.anticon.anticon-dashboard {
  color: #8b5cf6;
}
.driver-avatar .driver-avatar .avatar-status div {
  width: 100%;
}
.vehicle-information .ant-modal-content {
  padding: 0;
  background: white;
  overflow: hidden;
}
.-information .ant-modal-header {
  background: #8680f9;
  padding: 18px 22px;
  margin: 0;
}
.vehicle-information .ant-modal-title {
  color: #fff !important;
}
.vehicle-information .ant-modal-close {
  color: #fff !important;
  transition: all 0.3s ease;
}
.vehicle-information .ant-modal-close:hover {
  transform: rotate(90deg);
  transition: all 0.3s ease;
}
.vehicle-information .ant-modal-body {
  background: #fff;
  padding: 15px 0;
}
.vehicle-information .ant-modal-footer {
  background: #fff;
  padding: 15px 16px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f1f1f1;
}
.vehicle-information .ant-card {
  margin: 0;
}
.vehicle-information .ant-form-item {
  margin: 0;
}
.vehicle-information .ant-modal-footer .ant-btn-default {
  background: #ededed;
}
.vehicle-information .ant-modal-body {
  padding: 0;
}
.vehicle-information .page-header-alt {
  background-color: #8680f9;
}
.vehicle-inforvehiclemation .ant-tabs-nav-wrap {
  margin-top: 80px;
  padding: 0 20px;
}
.vehicle-information .modal-title {
  margin: 0;
  padding: 0 20px;
  color: white;
  font-size: 20px;
}
.vehicle-information .ant-tabs {
  margin: 0 !important;
}
.vehicle-information .ant-tabs-content-holder {
  padding: 0 20px;
}
.vehicle-information .ant-modal-footer {
  display: none;
}
.license-information .modal-input {
  padding: 15px 20px !important;
}
.order-details-body {
  display: block;
  gap: 35px;
}
.user-section {
  width: 100%;
}
.map-section {
  width: 100%;
  height: 465px;
}
.user-description .user-details-sec {
  width: 50%;
}
.user-description .user-details-sec .status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 20px;
  color: black;
  font-weight: 500;
  border-bottom: 1px solid #cfcfcfa6;
  padding-bottom: 20px;
}
.user-description .user-details-sec .status .ant-tag {
  font-size: 14px;
}
.user-description .user-details-sec .status-set {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 500;
}
.user-description .user-details-sec .status-set .status-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-top: 15px;
}
.user-description .user-details-sec .status-set .status-btns .ant-btn {
  padding: 0px 16px !important;
  line-height: 1 !important;
  height: 30px;
}

.order-details-body .details {
  margin-top: 10px;
  border-bottom: 1px solid #cfcfcfa6;
  padding-bottom: 30px;
}
.order-details-body .details .details-img {
  width: 100px !important;
  aspect-ratio: 1/1 !important;
  overflow: hidden;
}
.order-details-body .details-text h1 {
  font-size: 26px;
}
.order-details-body .details-text .identity {
  display: flex;
  justify-content: start;
  width: 100%;
}
.order-details-body .details-text .identity span {
  font-size: 14px;
}
.details .ant-card {
  margin-bottom: 0px;
  border: 0;
}
.user-section .user-description {
  display: flex;
  gap: 40px;
  background-color: white;
  border-radius: 10px;
}
.map-height .ant-card-body {
  height: 100%;
}
.trip-kilometer {
  margin-top: 15px;
}
.trip-kilometer h4 {
  margin: 0;
}
.trip-kilometer h5 {
  color: #0958d9;
}
.car-details-heading {
  margin-top: 20px;
  border-bottom: 1px solid rgb(194, 194, 194);
  text-transform: uppercase;
}
.car-details {
  position: relative;
  /* margin-top: 8px; */
  display: flex;
  gap: 14px;
  /* background: #e4e4f587; */
  border-bottom: 1px solid #8080803d;
  padding: 15px;
  /* border-radius: 10px; */
  /* align-items: center; */
}
.car-details .car-icons {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 5px;
}
/* .car-details .car-description{margin-top: 12px; display: flex; align-items: center; gap: 12px; flex-wrap: wrap;} */
.car-details .car-description-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  padding-right: 10px;
  border-right: 1px solid rgb(163, 163, 163);
}
.car-passenger-icon {
  font-size: 18px;
  color: #08c;
  border: 1px solid #08c;
  border-radius: 50px;
  padding: 1px;
}
.car-details .car-economy {
  color: #646464;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: end;
  background: #f4d8ff;
  padding: 2px 8px;
}
.car-details :where(.css-dev-only-do-not-override-1u35f9t).ant-avatar > img {
  object-fit: contain !important;
}
.car-details-title {
  margin-bottom: 6px;
  display: flex;
  justify-content: end;
  align-items: start;
}
.dashed {
  border-right: 1px solid #bdbdbd;
}
.details .ant-card-body {
  padding: 0;
}
/* .car-details-title {
  margin-bottom: 6px;
} */
.car-details-title h4 {
  margin: 0;
}
.car-details .car {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  aspect-ratio: 1/1;
  overflow: hidden;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #bdbdbd;
  padding: 5px;
  flex-shrink: 0;
}

.car-invoice {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  aspect-ratio: 1/1;
  overflow: hidden;
  background-color: #fff;
  border-radius: 100px;
  border: 1px solid #bdbdbd;
  padding: 5px;
  flex-shrink: 0;
}

.order-details-body .trip-date {
  font-size: 15px;
  color: rgb(111, 111, 111);
  margin-bottom: 15px;
}
.trip-date-2 {
  font-size: 16px;
  color: rgb(111, 111, 111);
  margin-bottom: 30px;
}
.order-details-body .trip-card {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(225 225 225);
  margin-bottom: 20px;
  display: flex;
}
.order-details-body .trip-card .trip-card-picup {
  width: 50%;
  /* border-right: 1px solid #8080805c; */
}
.order-details-body .trip-card .distance-sec {
  width: 50%;
  /* padding-left: 50px; */
  display: flex;
  flex-direction: column;
  border: 1px solid #bbb8b89e;
  border-radius: 8px;
  /* padding: 15px; */
}
.distance-sec .distance .travel-distance {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
  margin-top: 16px;
}
.distance-sec .payment-details {
  margin-top: auto;
}

.transection-car-details {
  position: relative;
  margin-top: 8px;
  display: flex;
  gap: 14px;
  /* border-top: 1px solid rgb(225 225 225); */
  /* padding-top: 15px; */
  border-bottom: 1px solid rgb(225 225 225);
  padding: 15px;
  align-items: center;
}
.transection-car-details .car {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  /* height: 55px; */
  aspect-ratio: 1/1 !important;
  background-color: #fff;
  border-radius: 100px;
  margin-left: auto;
  border: 1px solid #cfcfcf;
}
.transection-car-details .car-details-title .car-economy {
  background-color: #a9a4ff5c;
  color: #0958d9;
  font-weight: 500;
  text-align: center;
  border-radius: 8px;
  padding: 2px 8px;
}
.driver-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
.transection-car-details .car-details-title {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 12px;
}
.transection-car-details .car-icons {
  display: flex;
  align-items: center;
  gap: 6px;
}
.transection-email-label {
  font-size: 16px;
}
.transection-email-label span {
  font-size: 16px;
  padding-left: 8px;
}
.invoice-user-details {
  width: 40%;
}
.invoice-payment-details {
  width: 60%;
}
.invoice-payment-details h2 {
  font-size: 26px;
}
.invoice-payment-details-invoice {
  width: 100%;
}
.invoice-payment-details-invoice h2 {
  font-size: 26px;
}
.border-dashed {
  border-bottom: 1px dashed rgb(213, 213, 213);
  padding-top: 10px;
  padding-bottom: 5px;
}
.invoice-user-details .ant-card-body {
  box-shadow: 2px 3px 10px -3px #80808096;
  border: 0 !important;
}
.invoice-payment-details .ant-card-body {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.invoice-payment-details .ant-card-body {
  box-shadow: 2px 3px 10px -3px #80808096;
  border: 0 !important;
}
.invoice-payment-details-invoice .ant-card-body {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.invoice-payment-details-invoice .ant-card-body {
  box-shadow: 2px 3px 10px -3px #80808096;
  border: 0 !important;
}
.invoive-date-time {
  display: flex;
  gap: 40px;
  text-align: center;
}
.invoice-payment-details .ant-card-body .transection-trip-card-picup {
  width: 100%;
}
.invoice-payment-details .ant-card-body .distance-sec {
  width: 80%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}
.invoice-payment-details-invoice .ant-card-body .distance-sec {
  width: 80%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}
.invoice-payment-details-invoice .ant-card-body .transection-trip-card-picup {
  width: 100%;
}
.invoice-logo {
  width: 120px;
  height: 60px;
  object-fit: contain !important;
  margin-top: 22px;
}

.invoice-payment-details .ant-card-body .distance-sec {
  width: 80%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}
.invoice-email-label {
  color: #606060 !important;
}
.invoice-email-label span {
  color: #72849a !important;
  padding-left: 5px;
}
.trip-title-invoice {
  margin-top: 0;
  padding-top: 0;
}
.invoice-trip-date {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.invoice-identification {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 3px;
  justify-content: space-between;
}
.border-invoice-2 {
  border-bottom: 1px solid rgba(217, 217, 217, 0.864) !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-radius: 0 !important;
}
.border-invoice {
  border: 1px solid rgba(217, 217, 217, 0.864) !important;
}
.welcome-invoice {
  font-size: 20px;
  color: #1e1e1e;
  margin-top: 14px;
  padding-left: 15px;
}
.welcome-invoice span {
  text-transform: uppercase;
}
.payment-border-b {
  border-bottom: 1px solid rgba(179, 179, 179, 0.864) !important;
}
.distance-invoice .travel-distance {
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  padding: 15px;
  padding-top: 0;
}
.license-information-invoice .ant-form {
  padding: 0 20px;
}
.license-information .ant-modal-title h2 {
  color: white !important;
}
.enquiry-img {
  width: 60px;
  aspect-ratio: 1/1;
  overflow: hidden;
  margin-right: 20px;
}
.enquiry-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.enquiry-details .ant-card-head {
  padding: 0 20px;
}
.enquary-edit .ant-modal-body {
  padding: 20px 20px;
}
.edit-distance .ant-modal-body {
  padding: 0;
}
.edit-distance .edit-distace-title {
  padding: 20px;
  background-color: #8680f9;
  color: white;
}
.edit-distance .edit-distace-title h2 {
  margin: 0;
  color: white;
  font-size: 20px;
}
.edit-distance .ant-form {
  padding: 20px;
}
.edit-distance-rate .ant-modal-body {
  padding: 0;
}
.edit-distance-rate .distance-rate-title {
  padding: 20px;
  background-color: #8680f9;
  color: white;
}
.edit-distance-rate .distance-rate-title h2 {
  margin: 0;
  color: white;
  font-size: 20px;
}
.edit-distance-rate .ant-form {
  padding: 20px;
}
.distance-rate-distance {
  width: 100%;
}
.distance-rate-minus {
  width: 5%;
}
.city-price-edit .ant-modal-body {
  padding: 0;
}
.city-price-edit .city-price-title {
  padding: 20px;
  background-color: #8680f9;
  color: white;
}
.city-price-edit .city-price-title h2 {
  margin: 0;
  color: white;
  font-size: 20px;
}
.city-price-edit .ant-form {
  padding: 20px;
}

.passenger-details {
  width: 100%;
  background-color: #f4f3ff;
  padding: 20px;
  padding-top: 36px;
  border-radius: 15px;
}
.passenger-details .passenger-icon {
  border: 1px solid #d1d1d1;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  padding: 6px;
  color: #756ef5 !important;
  font-size: 17px !important;
}
.passenger-details .user-information {
  font-weight: 500;
  font-size: 17px;
  color: #545454;
  margin-left: 15px;
}
.passenger-details .user-information-details {
  font-size: 14px;
  color: #646464;
  font-weight: 500;
}
.passenger-detail-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  max-height: 70px;
}
.passenger-detail-title h1 {
  color: blue;
  font-size: 26px;
  line-height: 1;
}
.passenger-heading {
  font-size: 27px;
  font-weight: 500;
  margin-bottom: 10px;
  color: black;
  border-bottom: 1px solid rgba(227, 227, 227, 0.835);
  padding-bottom: 6px;
  margin-bottom: 20px;
}
.passenger-avatar .ant-avatar {
  height: 270px !important;
  border: 2px solid #8680f9;
  border-radius: 6px;
}
.pl-23 {
  padding-left: 23px;
}
.passenger-info-wraper {
  width: 100%;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 24px;
  gap: 0!important;
}
.booking .user-icon {
  margin-right: 6px;
  margin-left: -2px;
  border: 1px solid #756ef5;
  color: #756ef5;
  width: 24px;
  aspect-ratio: 1/1;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.booking .user-booking {
  font-size: 13px;
  margin-bottom: 12px;
  line-height: 1;
}
.booking .user-booking-date {
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 500;
}

.enquiry-details .enquiry-chatbox {
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
}
.enquiry-chatbox .chatbox-body {
  min-height: 400px;
  max-height: 500px;
  overflow: auto;
  padding: 20px 100px;
}
.enquiry-chatbox .user-img-wrapper {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50px;
  overflow: hidden;
}
.enquiry-chatbox .user-img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.enquiry-chatbox .chatbox-body .chat-box-left {
  width: 60%;
  display: flex;
  margin-top: 10px;
}
.enquiry-chatbox .chatbox-body .chat-box-left .chats {
  font-size: 14px;
  background-color: #d9d7ff;
  color: rgb(0, 0, 0);
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  margin-left: 14px;
  width: 100%;
}

.enquiry-chatbox .chatbox-body .chat-box-left .chats-time {
  margin-left: 14px;
  font-size: 12px;
  padding-top: 5px;
  font-weight: 500;
  color: rgb(105, 105, 105);
}

.enquiry-chatbox .chatbox-body .chat-box-right {
  width: 60%;
  display: flex;
  justify-content: end;
  width: 100%;
}
.enquiry-chatbox .chatbox-body .chat-box-right .chats {
  position: relative;
  font-size: 14px;
  background-color: #8680f9;
  color: white;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0px;
  margin-top: 10px;
}
.enquiry-chatbox .chatbox-body .chat-box-right .chat-right-time {
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
  width: 60%;
  margin-top: 10px;
}
.enquiry-chatbox .chatbox-body .chat-box-right .img-sec {
  display: flex;
  justify-content: end;
  align-items: start;
}
.enquiry-chatbox .chatbox-body .chat-box-right .chats-time-2 {
  margin-right: 14px;
  font-size: 12px;
  padding-top: 5px;
  font-weight: 500;
  color: rgb(105, 105, 105);
  text-align: end;
}

.chat-date {
  font-size: 14px;
  color: #929292;
  text-align: center;
  position: relative;
  width: 80%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.chat-date::after {
  width: 330px;
  height: 0.5px;
  background-color: #929292;
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.chat-date::before {
  width: 330px;
  height: 0.5px;
  background-color: #929292;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.transection-logo {
  width: 200px;
  aspect-ratio: 2/1;
  overflow: hidden;
}
.transection-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.chatbox-footer {
  display: flex;
  align-items: center;

  background-color: #f1f1f1;
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 20px;
  padding: 10px 100px;
}

.message-input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  resize: none;
  font-size: 14px;
  outline: none;
}

.send-button {
  background-color: #8b5cf6;
  color: white;
  border: none;
  width: 50px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
}

.send-button:hover {
  background-color: #6f44d2;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.align-start {
  text-align: start;
}
.width-75 {
  width: 75%;
}
.width-25 {
  width: 25%;
}
.width-50 {
  width: 50%;
}

.admin-form-sec {
  border: 1px solid #e6ebf1;
  padding: 10px;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 20px;
}
.border-left {
  border-left: 1px solid #e6ebf1;
}

.edit-driver-header {
  margin-bottom: -10px !important;
}
.licensegallery .license-gallery-img {
  width: 30%;
}
.driver-licence-gallery-card .ant-card-body {
  padding-top: 0;
  padding-bottom: 10px;
}
.license-gallery-img {
  border: 1px solid #dddddd;
  border-radius: 20px;
  padding: 25px !important;
  background: #f5f5f5;
}
.insurancegallery .insuracefront {
  width: 30%;
}
.insuarance-title-mb {
  padding-bottom: 25px;
}
.insuarance-title-mb
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-head-title {
  margin-bottom: 20px;
}
.drugtest {
  margin: 15px;
}
.drugtest .drugtest-form {
  width: 33%;
}
.drug-gallery {
  width: 30%;
}
.vehicle-info {
  margin: 15px;
}
.transection-id {
  font-size: 20px;
  padding-left: 8px;
}
.w-200 {
  width: 200px;
}
/* .ant-table-wrapper .ant-table-thead >tr>th,.ant-table-wrapper .ant-table-thead >tr>td{background: rgb(86 77 245 / 10%);} */

.social-media-content {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a !important;
  border-radius: 10px;
}
.social-icon-function {
  display: flex;
  gap: 12px;
}

.dashboard-add-booking {
  position: absolute;
  top: 25px;
  right: 30px;
}
.imageWrap {
  aspect-ratio: 1/1 !important;
  border-radius: 50% !important;
  border: 1px dashed gray !important;
}
.imageWrap .ant-image {
  aspect-ratio: 1/1 !important;
  border-radius: 50% !important;
}
.dashboard-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 14px;
  padding-bottom: 10px;
}
.boxes-skeleton-2 {
  width: 100%;
  padding: 18px;
  border-radius: 8px !important;
  margin: 0;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  transition: all 0.3s ease;
  background-color: #efefef;
  border: 1px solid #a9c6ff;
  height: 100%;
}
.ant-menu-light .ant-menu-item-selected {
  background-color: white !important;
  color: #4782ff !important;
}
.ant-pagination .ant-pagination-item-active {
  color: white !important;
}

.trip-card-picup .ant-row {
  flex-flow: nowrap !important;
}
.add-modal-bg .ant-modal-content {
  padding: 0;
}
.add-modal-bg .ant-modal-content .ant-modal-body h2 {
  padding: 20px 25px;
  color: white !important;
}
.add-modal-bg .ant-modal-content .ant-modal-body .modal-title {
  background-color: #4782ff;
  border-radius: 10px 10px 0px 0px;
}
.add-modal-bg .ant-modal-content .ant-modal-body .ant-form {
  padding: 0px 25px 8px;
}

/* .passenger-avatar{width: 220px;} */

/*******************************************table css*******************************************/
/* .ant-table .ant-table-container .ant-table-body{border:1px solid #f3f3f3!important; border-radius: 0px 0px 12px 12px!important; border-top: 0!important;}
.ant-table-tbody .ant-table-row {
  border-bottom: 0.5px solid #dddddd00 !important;
} */

/* .ant-table-tbody .ant-table-row{} */

/***************************************BOOKING LIST PAGE****************************************************************/
.booking-list-sec {
  display: flex;
  gap: 28px;
  width: 100%;
}
.booking-list-sec .booking-users {
  width: 30%;
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(218, 218, 218);
  min-height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.booking-list-sec .booking-users .user-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
}
.booking-users .search-bar {
  margin-bottom: 16px;
}
.booking-list-sec .booking-users .heading {
  font-size: 26px;
  font-weight: 500;
  color: black;
}
.booking-list-sec .booking-user-details {
  width: 70%;
}
.booking-list-sec .booking-user-details .user-detailsscroll {
  min-height: 400px;
  max-height: 500px;
  overflow: auto;
  padding: 10px 24px;
}
.booking-list-sec .booking-users .user-list {
  margin-bottom: 15px;
  padding: 12px 18px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  border: 1px solid rgb(218 218 218 / 59%);
  box-shadow: 0px 2px 13px -5px rgb(200, 200, 200);
  background: rgba(255, 255, 255, 0.07);
}
.booking-list-sec .booking-users .active {
  background: rgb(85 77 245 / 7%) !important;
}
/* .booking-list-sec .booking-users .user-list .user-img-width {
  width: 16%;
} */
.booking-list-sec .booking-users .user-list .user-img {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50px;
  overflow: hidden;
}
.booking-list-sec .booking-users .user-list .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.booking-list-sec .booking-users .user-list .username {
  width: 100%;
  text-align: start;
}
.booking-list-sec .booking-users .user-list .username h4 {
  margin: 0;
  font-size: 16px;
  line-height: 1;
}
.booking-list-sec .booking-users .user-list .username p {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  /* margin-top: 6px; */
  color: rgb(142, 142, 142);
  word-break: break-all;
}
.user-list-date {
  margin-right: 0;
  font-size: 10px !important;
  text-transform: capitalize;
  margin-top: 0;
}
.g-mail-icon {
  border: 1px solid #08c;
  padding: 5px;
  border-radius: 50px;
  color: #08c;
}
.booking-list-sec .booking-users .user-list .user-active {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: end;
  background: #699dff2b;
  border: 1px solid blue;
  border-radius: 6px;
  padding: 2px;
  color: blue;
}

.booking-list-sec .booking-user-details {
  width: 70%;
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(218, 218, 218);
  transition: 0.5s all ease;
}
.booking-list-sec .booking-user-details .details-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(218, 218, 218, 0.657);
  justify-content: space-between;
  margin-bottom: 6px;
  padding-bottom: 10px;
}
.booking-list-sec .booking-user-details .details-header .icons {
  display: none;
  align-items: center;
  gap: 14px;
  margin-bottom: 6px;
}
.booking-list-sec .booking-user-details .details-header .header-icon {
  font-size: 16px;
  padding: 2px 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
}
.booking-list-sec .booking-user-details .details-header .user-date {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  gap: 10px;
  align-items: center;
}
.booking-list-sec .booking-user-details .details-header .time {
  font-size: 14px;
  color: gray;
}
.booking-list-sec .booking-user-details .user-details {
  position: relative;
  padding: 18px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bdbdbd85;
  margin-bottom: 18px;
  gap: 25px;
}
.booking-list-sec .booking-user-details .user-details .user-status {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.booking-list-sec .booking-user-details .user-details .user-status .ant-tag {
  font-size: 16px;
}
.booking-list-sec .booking-user-details .user-details .user-title {
  display: flex;
  gap: 16px;
  align-items: start;
  width: 50%;
}
.booking-list-sec .booking-user-details .user-details .user-title .user-avatar {
  width: 60px;
  aspect-ratio: 1/1;
  border-radius: 50px;
  overflow: hidden;
  flex-shrink: 0;
}
.booking-list-sec .booking-rangepicker {
  width: 197px;
}
.booking-list-sec .booking-rangepicker .ant-picker {
  border: 1px solid #dfdfdf !important;
  border-radius: 6px !important;
}
.booking-list-sec
  .booking-user-details
  .user-details
  .user-title
  .user-avatar
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name-passenger {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-info-passenger {
  font-size: 10px !important;
  text-transform: uppercase;
  font-weight: 500;
}
.user-info-passenger .font-size {
  font-size: 10px !important;
  margin-right: 0;
}
.border-right-user {
  border-right: 1px solid rgb(209, 209, 209);
  padding-right: 25px;
}

.booking-list-sec .booking-user-details .user-details .user-title .name {
  font-size: 20px;
  font-weight: 600;
  color: black;
  line-height: 1;
  text-transform: capitalize;
  /* margin-bottom: 6px; */
}
.booking-list-sec .booking-user-details .user-details .email-address {
  display: flex;
  gap: 10px;
  font-size: 16px;
  align-items: center;
  margin-top: 4px;
}
.details-header .status {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}
.details-header .status .download-invoice-icon .ant-btn {
  background-color: rgb(255, 255, 255);
  width: 40px;
  aspect-ratio: 1/1;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  color: red;
  border-radius: 5px;
  border: 1px solid #dddddd;
  margin-top: 0 !important;
  box-shadow: rgba(143, 143, 143, 0.22) 0px 2px 2px;
}
.user-information {
  display: flex;
  gap: 10px;
  font-size: 16px;
  align-items: center;
}
.user-information .passenger {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 8px;
  font-weight: 400;
}
/* .user-information .user-info-icon{   
   border-right: 1px solid #b7b7b7;
  padding-right: 8px;
} */
.booking-list-sec
  .booking-user-details
  .user-details
  .email-address
  .mail-icon {
  display: flex;
  gap: 10px;
  color: black;
  font-weight: 500;
  /* border-right: 1px solid #b7b7b7;
  padding-right: 8px; */
}
.booking-list-sec .booking-user-details .user-details .email-address .email {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 8px;
}
.trip-card-picup h2 {
  font-size: 20px;
}
/* .booking-list-sec .booking-user-details .user-details .email-address .email span{ display: flex; justify-content: end;} */
.vehicle-details {
  margin-top: 20px;
}
.vehicle-details .vehicle-title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.vehicle-details .vehicle-title .vehicle-img {
  width: 75px;
  aspect-ratio: 1/1;
  border-radius: 50px;
  overflow: hidden;
}
.vehicle-details .vehicle-title .vehicle-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vehicle-details .vehicle-title .vehicle-name {
  font-size: 20px;
  color: rgb(23, 23, 23);
  font-weight: 500;
}
.vehicle-details .vehicle-title .vehicle-number {
  font-size: 15px;
  color: rgb(74, 74, 74);
  font-weight: 500;
}
.vehicle-details .vehicle-title .vehicle-number span {
  padding-left: 20px;
}

.user-footer {
  display: flex;
  padding-top: 28px;
  justify-content: space-between;
  align-items: center;
}
.resetadd-btn {
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
  position: absolute;
  top: 22px;
  right: 30px;
}
.user-pagination .ant-pagination {
  margin-top: 0px !important;
}
.distance-sec .car-details .car-description {
  width: 100%;
}
.distance-sec .car-details .car-description .car-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}
.padding-15 {
  padding: 0 15px 15px 15px;
}

.user-pagination .ant-pagination .ant-pagination-item {
  background-color: white !important;
  color: #6f44d2 !important;
  border-radius: 50px !important;
  border: 1px solid #6f44d2 !important;
}

.user-pagination .ant-pagination .ant-pagination-item-active {
  background-color: #6f44d2 !important;
  color: white !important;
  border-radius: 50px !important;
}

.email-address .ant-btn {
  border: 0;
  padding: 0;
  height: 28px;
}
.ant-tooltip-cyan {
  margin: -23px 0 !important;
}
.user-header .search-bar {
  width: 100%;
}
.border-0 {
  border: 0 !important;
  width: 100% !important;
}
.user-header .search-bar {
  margin-bottom: 18px;
}
.user-list-height {
  max-height: 800px;
  min-height: 800px;
  overflow: auto;
  padding-right: 10px;
}

/* .booking-user-details {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  
} */

.booking-user-details.open {
  opacity: 1;
}

.booking-user-details.closed {
  opacity: 0;
}

.user-pagination ul li {
  min-width: 36px !important;
  height: 36px !important;
}
.user-pagination ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-bg {
  background: #fff;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  border: 1px solid #d3d3d385;
  border-radius: 10px;
  overflow: hidden;
  padding: 15px;
}
/* .form-bg-none{background: none!important; box-shadow: none!important;} */
.img-border-right {
  border-right: 1px solid #dbdbdb;
  padding-right: 16px;
}
.flex-col {
  flex-direction: row !important;
}
.img-padding-form {
  padding: 8px 50px 10px 18px;
}

.order-details-body-2 .trip-card {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(225 225 225);
  margin-bottom: 30px;
}
.order-details-body-2 .trip-date {
  font-size: 16px;
  color: rgb(111, 111, 111);
  margin-bottom: 15px;
}
.order-details-body-2 .details-2 {
  width: 50%;
}
.order-details-body-2 .details-2 .details-img {
  width: 100px !important;
  aspect-ratio: 1/1 !important;
  overflow: hidden;
}
.order-details-body-2 .details-text h1 {
  font-size: 26px;
}
.order-details-body-2 .details-text .identity {
  display: flex;
  justify-content: start;
  width: 100%;
}
.order-details-body-2 .details-text .identity span {
  font-size: 14px;
}
.order-details-body-2 {
  display: flex;
  gap: 35px;
}
.car-details-title-2 {
  margin-bottom: 6px;
}
.car-details-title-2 h4 {
  margin: 0;
}
.car-details-2 .car {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 100px;
}
.car-details-heading {
  margin-top: 20px;
  border-bottom: 1px solid rgb(194, 194, 194);
  text-transform: uppercase;
}
.car-details-2 {
  position: absolute;
  top: 20px;
  right: 20px;
  margin-top: 8px;
  display: flex;
  gap: 14px;
}
.car-details-2 .car-icons {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 5px;
}
.car-details-2 .car-description {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}
.car-details-2 .car-description-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  padding-right: 10px;
  border-right: 1px solid rgb(163, 163, 163);
}
.car-details-2 .car-economy {
  color: #646464;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12px;
}
.car-details-2 :where(.css-dev-only-do-not-override-1u35f9t).ant-avatar > img {
  object-fit: contain !important;
}
.car-details-title-2 {
  align-items: center;
  gap: 14px;
}
.user-section-2 .user-details-2 {
  display: flex;
  gap: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid rgb(225 225 225);
}
.user-section-2 {
  width: 55%;
}
.map-section-2 {
  width: 45%;
}
.details-2 .ant-card {
  margin-bottom: 0px;
  border: 0;
}
.details-2 .ant-card-body {
  padding: 5px;
}
.details-user-img {
  width: 40px;
  aspect-ratio: 1/1;
  overflow: hidden;
}
.details-user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.vehicle-add-modal .ant-tabs .ant-tabs-nav {
  margin-top: 80px;
  padding: 0px 24px;
}
.custom-avatar-style {
  width: 100% !important;
}
.gallery-bg {
  background: rgb(86 77 245 / 10%);
}
.vehicle-reg-gallery {
  width: 50%;
}
.vehicle-reg-gallery .ant-card {
  background: rgba(95, 86, 255, 0.1);
}
.vehicle-reg-gallery .ant-card .ant-upload-list-item {
  background: rgb(255, 255, 255);
}
.vehicle-reg-gallery .ant-card .ant-upload {
  background: rgb(255, 255, 255);
  border-radius: 10px !important;
}
.vehicle-gallery-sec {
  display: flex;
  gap: 30px;
}
.drivertabwrapper .ant-card {
  background: #d5cffd4f;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.admin-form-sec .ant-card-body {
  padding: 5px;
}
.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td .avatar-status .w-100 {
  width: auto !important;
}

.modal-cross .ant-modal-content .ant-modal-close {
  background: white !important;
  border: 1px solid gray !important;
  border-radius: 80px !important;
  color: gray !important;
  width: 30px !important;
  height: 30px !important;
  align-items: center !important;
  justify-content: center !important;
  transition: 0.5s all ease;
}

/* .modal-cross .ant-modal-content .ant-modal-body h2{border-bottom: 1px solid rgb(229, 229, 229); padding-bottom: 10px;} */
.modal-cross .ant-modal-content .ant-modal-close:hover {
  color: #3e79f7 !important;
  border-color: #3e79f7 !important;
  rotate: 90deg;
}
.modal-header-bg {
  background-color: #3e79f7 !important;
  color: white !important;
  padding: 8px;
}
.permission-modal .ant-modal-content .ant-modal-body {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}
.add-modal-width {
  width: 70% !important;
}

/************************************edit profile**********************************/
.edit-profile-row {
  gap: 20px;
  display: flex;
}
.edit-profile-row .rofile-info .ant-card-body {
  display: flex;
}
.width-30per {
  width: 30%;
}
.width-70per {
  width: 70%;
}
.form-bg-none {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}
.card-box-shadow {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
}
.frofile-changepass-form-item {
  margin-bottom: 17px !important;
}
.profile-ph-no
  .ant-form-item-control
  .ant-input-group-addon
  .ant-select-status-success {
  width: 64px !important;
}
.width-65-card {
  width: 65%;
}
.width-35-card {
  width: 35%;
}

/**********************************Table Skeleton*************************************/
.custom-table-skeleton {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
}
.custom-table-skeleton .ant-table {
  width: 100%;
}
.custom-table-skeleton .ant-table .ant-table-tbody .ant-table-cell {
  position: relative;
}
.custom-table-skeleton .ant-table .ant-table-tbody .ant-table-cell:before {
  content: "";
  top: 0;
  left: 0;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
}

.custom-table-skeleton th,
.custom-table-skeleton td {
  padding: 8px;
  text-align: center;
}

.custom-table-skeleton .ant-table th {
  background-color: rgb(86 77 245 / 10%) !important;
}
.ant-skeleton.ant-skeleton-element .ant-skeleton-input-sm {
  min-width: 100%;
}

/**********************************Driver details skeleton********************************/
.driver-detail-wrapper .ant-skeleton .ant-skeleton-image {
  height: 368px;
  width: 280px;
}
.info-display-skeleton {
  width: 100%;
  margin: 0;
  column-count: 2;
  gap: 100px;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 10px;
}
.avatar-detail-skeleton {
  display: block;
  width: 100%;
}
.avatar-details-loader {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  max-height: 70px;
}
.verify-outer-wrapper {
  margin-top: 40px;
}
.verify-outer-wrapper-skeleton {
  margin-top: 26px;
}

/*********************************Booking details skeleton****************************************************/
.car-details-2 .car-skeleton {
  display: block;
}
.boxes-skeleton {
  width: 200px;
  padding: 6px;
  border-radius: 8px !important;
  margin: 0;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  transition: all 0.3s ease;
  /* background: #f0efff; */
  background-color: #efefef;
  border: 1px solid #a9c6ff;
  height: 100%;
}
.ant-card.boxes-skeleton .ant-card-head-title {
  text-transform: uppercase;
  font-size: 14px;
  text-align: right;
  color: #5a5a5a;
}

/************************************************* New Dashboard *************************************************************/

/* .dashboard-box-2{display: flex; grid-column: 5; gap: 20px;} */
.dashboard-box-2 {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
}

.dashboard-box-2 .boxes-2 {
  /* width: 250px; */
  width: 100%;
  padding: 6px;
  border-radius: 8px !important;
  margin: 0;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  transition: all 0.3s ease;
  background: #ffffff;
  /* border-bottom: 5px solid #7686d9; */
  height: 100%;
}
.dashboard-box-2 a:nth-child(1) .boxes-2 {
  border-right: 6px solid #7686d9 !important;
  border: 1px solid #7686d9;
}
.dashboard-box-2 a:nth-child(2) .boxes-2 {
  border-right: 6px solid #ffca2a !important;
  border: 1px solid #ffca2a;
}
.dashboard-box-2 a:nth-child(3) .boxes-2 {
  border-right: 6px solid #09ac93 !important;
  border: 1px solid #09ac93;
}
.dashboard-box-2 a:nth-child(4) .boxes-2 {
  border-right: 6px solid #e246c6 !important;
  border: 1px solid #e246c6;
}
.dashboard-box-2 a:nth-child(5) .boxes-2 {
  border-right: 6px solid #2db836 !important;
  border: 1px solid #2db836;
}

.dashboard-box-2 .boxes-2:hover {
  box-shadow: 0 4px 8px rgba(23, 17, 562, 0.4);
}

.ant-card.boxes-2 .ant-card-head-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  text-align: right;
  color: #5a5a5a;
}
.ant-card.boxes-2 .ant-card-body {
  padding-top: 20px;
  padding-bottom: 16px;
  box-shadow: none;
}
.boxes-2 .boxesicon {
  position: relative;
  top: 0px;
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  left: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2;
}
.boxes-2 .boxesicon::before {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  width: 76px !important;
  height: 76px;
  border-radius: 80px;
  z-index: 1;
}

.dashboard-box-2 a:nth-child(1) .boxes-2 .boxesicon {
  background: #1c57d5;
}
.dashboard-box-2 a:nth-child(2) .boxes-2 .boxesicon {
  background: #f7bc0d;
}
.dashboard-box-2 a:nth-child(3) .boxes-2 .boxesicon {
  background: #09ac93;
}
.dashboard-box-2 a:nth-child(4) .boxes-2 .boxesicon {
  background: #e246c6;
}
.dashboard-box-2 a:nth-child(5) .boxes-2 .boxesicon {
  background: #2db836;
}

.dashboard-box-2 a:nth-child(1) .boxes-2 .boxesicon::before {
  background: #1c56d55f;
}
.dashboard-box-2 a:nth-child(2) .boxes-2 .boxesicon::before {
  background: #ffca2a85;
}
.dashboard-box-2 a:nth-child(3) .boxes-2 .boxesicon::before {
  background: #0dc9ad7d;
}
.dashboard-box-2 a:nth-child(4) .boxes-2 .boxesicon::before {
  background: #ff5ee26f;
}
.dashboard-box-2 a:nth-child(5) .boxes-2 .boxesicon::before {
  background: #3ed1486c;
}
.ant-card.boxes-2 .ant-card-body span {
  text-align: right;
  display: block;
  font-size: 26px;
  font-weight: bold;
  z-index: 2;
}
.boxes-2 .box-icon-count {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.boxes-2 .box-icon-count .card-title {
  text-transform: uppercase;
  font-size: 14px;
  text-align: right;
  color: #5a5a5a;
  font-weight: 600;
}
.width-40 {
  width: 45%;
}
.height-according-chart {
  max-height: 600px;
  overflow: auto;
}
.dashboard-card-shadow {
  box-shadow: 2px 2px 13px -4px gray;
}

.pie-chart-title {
  font-size: 24px;
  color: #1a3353;
  border-bottom: 1px solid rgb(203, 203, 203);
  padding-bottom: 5px;
}
.dashboard-pie-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 16px;
}
.pie-chart-wrapper .ant-card-body {
  width: 100%;
  height: 100%;
}
.dashboard-pie-chart .apexcharts-legend {
  top: 75px !important;
  right: 0px !important;
  font-size: 18px !important;
}
.active-user-chart-wrapper {
  width: 30% !important;
}
.column-chart-wrapper {
  width: 70% !important;
}
/* .active-user-chart-wrapper .ant-card-body .apexcharts-canvas {
  width: 450px !important;
} */
.dashboardrecentbooking
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-head-title {
  font-size: 24px;
  color: #1a3353;
  border-bottom: 1px solid rgb(203, 203, 203);
  padding-bottom: 5px;
}
.dashboardactivedriver
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-head-title {
  font-size: 24px;
  color: #1a3353;
  border-bottom: 1px solid rgb(203, 203, 203);
  padding-bottom: 5px;
}
.active-user-chart-wrapper {
  display: flex;
}
.active-user-chart-wrapper .ant-card-body {
  width: 100%;
}
.column-chart-wrapper .apexcharts-canvas .apexcharts-svg {
  background: #e5fff45b !important;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
}
.pie-chart-skeleton {
  width: 55% !important;
  border-radius: 100px !important;
  aspect-ratio: 1 / 1;
}
.pie-chart-skeleton .ant-skeleton-image {
  width: 100% !important;
  height: 100% !important;
  border-radius: 1000px !important;
}
.pie-chart-skeleton-wrapper .ant-card-body {
  width: 100% !important;
  display: flex;
  height: 100% !important;
  align-items: center !important;
}
.pie-chart-skeleton-wrapper .ant-card-body .pie-chartdes-skeleton {
  width: 45%;
  padding-left: 20px !important;
}
.width-60-skeleton {
  width: 60%;
}
.width-40-skeleton {
  width: 40%;
}
.skeleton-active-user .ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.booking .user-icon-calender {
  margin-right: 6px;
  margin-left: -2px;
  /* border: 1px solid #756ef5; */
  color: #756ef5;
  width: 24px;
  aspect-ratio: 1 / 1;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 18px;
}
.booking-date-picker .ant-form-item .ant-form-item-control-input .ant-form-item-control-input-content .ant-picker{width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 6px;}
  .dashboard-graph-width-sec{width: 100%;}
  .dashboard-piechart-width-sec{width: 100%;}
.ant-picker .ant-picker-input >input::placeholder,.ant-picker-suffix{color: #455560!important;}
.notification-img{width: 72px; aspect-ratio: 1/1; border-radius: 100px; overflow: hidden;}
.notification-img img{width: 100%; height: 100%; object-fit: cover;}
.notification-body{min-height: calc(78vh)!important;}
.notification-innerpart { border: 1px solid rgb(217, 217, 217);}
.notification-innerpart-active{ border-left: 6px solid #0d40b7!important;}
.notification-innerpart .ant-card-body{display: flex; gap: 25px; align-items: start; overflow: hidden;}
.notifiaction-text{width: 100%;}
.notification-title{color: #102cbf; background: #bbcfff67; padding: 3px 12px; border-radius: 10px;font-weight: 600;
  font-size: 14px;}
.notifiaction-text .notification-name{font-size: 22px; line-height: 1;} 
.notification-title-name{font-size: 16px; color: #0d40b7; display: flex; font-weight: 600; margin-bottom: 4px; border-radius: 10px; justify-content: space-between; align-items: center;}
.notifiaction-text .notification-description{font-size: 16px;}
.car-details-notification .car-img{width: 90px; aspect-ratio: 1/1; overflow: hidden; border-radius: 10px; border: 1px solid rgb(227, 227, 227);box-shadow: 1px 2px 7px -4px; }
.car-details-notification .car-img img{width: 100%; height: 100%; object-fit: contain;}
.notification-time{display: flex; align-items: center; gap: 6px; font-size: 14px; padding-top: 10px; font-weight: 600; color: #b2b2b2; line-height: 1;}
.notification-card-shadow {
  box-shadow: 1px 1px 13px -7px gray;
}
.notification-time-title{display: flex; align-items: center; justify-content: space-between;}
.notification-header-sec{display: flex; justify-content: space-between; border-bottom: 1px solid rgb(213, 213, 213); margin-bottom: 25px; align-items: center;}
.notification-heading{font-size: 24px; color: #011032; font-weight: 600; text-transform: uppercase;}
.notification-skeleton-img{width: 100%!important; height: 100%!important;}
.notification-skeleton-img .ant-skeleton-avatar{width: 100%!important; height: 100%!important;}
.notification-description .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph{display: none!important;}
.notification-title .ant-skeleton .ant-skeleton-button{height: 26px!important; background: rgb(69 85 96 / 0%)!important;}
.add-rating-modal .ant-modal-body .ant-form{padding: 0px 20px;}
/* .passenger-list-avatarName .avatar-status .ml-2{padding-left: 10px;} */

@media screen and (max-width: 1920px) {
  .chat-date::before {
    width: 330px;
  }
  .chat-date::after {
    width: 330px;
  }
}

@media screen and (max-width: 1600px) {
  .user-section .user-description {
    gap: 14px;
  }

  .chat-date::before {
    width: 250px;
  }
  .chat-date::after {
    width: 250px;
  }

  .booking-list-sec .booking-users .user-list .user-img {
    width: 43px;
  }
  .booking-list-sec .booking-users {
    width: 35%;
  }
  .booking-list-sec .booking-user-details {
    width: 65%;
  }
  .img-padding-form {
    padding: 16px 16px 0px 0px;
  }
  /* .order-details-body .trip-card .distance-sec {
    padding-left: 30px;
  } */
  .driver-detail-wrapper .ant-skeleton .ant-skeleton-image {
    width: 235px;
    height: 350px;
  }
  .modal-content-width-30 {
    width: 30%;
  }
  .modal-content-width-70 {
    width: 70%;
  }
  .boxes-2 .boxesicon::before {
    width: 66px !important;
    height: 66px;
  }
  .ant-card.boxes-2 .ant-card-body {
    padding: 20px 14px 16px 14px;
  }
  .active-user-chart-wrapper {
    width: 35% !important;
  }
  .column-chart-wrapper {
    width: 65% !important;
  }
  /* .active-user-chart-wrapper .ant-card-body .apexcharts-canvas{width: 800px!important;} */
}

@media screen and (max-width: 1440px) {
  .chat-date::before {
    width: 200px;
  }
  .chat-date::after {
    width: 200px;
  }
  .order-details-body .trip-card .trip-card-picup {
    width: 60%;
  }
  .booking-list-sec .booking-user-details .user-details .user-title .name {
    font-size: 24px;
  }
  .driver-detail-wrapper .ant-skeleton .ant-skeleton-image {
    width: 206px;
  }
  .boxes-2 .boxesicon {
    width: 42px;
    height: 42px;
  }
  .boxes-2 .boxesicon::before {
    width: 56px !important;
    height: 56px;
  }
}

@media screen and (max-width: 1366px) {
  .ant-card.boxes {
    width: 180px;
  }
  .chat-date::before {
    width: 175px;
  }
  .chat-date::after {
    width: 175px;
  }
  .booking-list-sec {
    gap: 20px;
  }
  .order-details-body .trip-card .trip-card-picup {
    width: 55%;
  }
  .order-details-body .trip-card .distance-sec {
    width: 45%;
    /* padding-left: 16px; */
  }
  .car-details .car-economy {
    font-size: 14px;
  }
  .distance-sec .car-details .car-description .car-name {
    font-size: 14px;
  }
  .distance-sec .distance .travel-distance {
    font-size: 14px;
  }
  .booking-list-sec
    .booking-user-details
    .user-details
    .user-title
    .user-avatar {
    width: 70px;
  }
  .booking-list-sec .booking-user-details .user-details .user-title .name {
    font-size: 20px;
  }
  .user-section-2 .user-details-2 {
    gap: 12px;
    flex-direction: column;
  }
  .order-details-body-2 .details-2 {
    width: 100%;
  }
  .dashed {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 15px;
    border-right: 0;
  }
  .order-details-body-2 {
    gap: 26px;
  }
  .order-details-body-2 .trip-card {
    margin-bottom: 20px;
  }
  .user-section-2 {
    width: 50%;
  }
  .map-section-2 {
    width: 50%;
  }
  .add-modal-width {
    /* width: 86% !important; */
    width: 70% !important;
  }
  .ant-card.boxes-2 .ant-card-body {
    padding: 12px 14px 8px 14px;
  }
  /* .boxes-2 .box-icon-count .card-title{padding-bottom: 22px;} */
  .boxes-2 .boxesicon {
    width: 36px;
    height: 36px;
  }
  .boxes-2 .boxesicon::before {
    width: 48px !important;
    height: 48px;
  }
  .ant-card.boxes-2 .ant-card-body {
    padding: 12px 10px 8px 6px;
  }
}

@media screen and (max-width: 1280px) {
  .driver-icon {
    top: 8px;
    right: 8px;
  }
  .enquiry-chatbox .chatbox-body {
    min-height: 400px;
    max-height: 500px;
    overflow: auto;
    padding: 20px 64px;
  }
  .order-details-body .trip-card {
    display: block;
  }
  .order-details-body .trip-card .trip-card-picup {
    width: 100%;
  }
  .order-details-body .trip-card .distance-sec {
    width: 100%;
    padding-left: 0px;
    margin-top: 28px;
  }
  .booking-list-sec .booking-users {
    width: 40%;
  }
  .booking-list-sec .booking-user-details {
    width: 60%;
  }
  .booking-list-sec .booking-user-details .user-details {
    gap: 22px;
  }
  .booking-list-sec .booking-user-details .user-details .user-title {
    width: 100%;
  }
  .booking-list-sec .booking-user-details .user-details {
    flex-direction: column;
  }
  .border-right-user {
    border-right: 0;
    padding-right: 0;
    border-bottom: 1px solid rgb(209, 209, 209);
    padding-bottom: 25px;
  }
  .map-section {
    height: 266px;
  }
  .driver-detail-wrapper .ant-skeleton .ant-skeleton-image {
    width: 188px;
  }
  .pie-chart-title {
    font-size: 22px;}
}

@media screen and (max-width: 1024px) {
  /* .barchart {
    display: flex;
  } */
  .order-details-body {
    display: block;
  }
  .user-section {
    width: 100%;
  }
  .trip-date-2 {
    margin-bottom: 18px;
  }
  .booking-list-sec
    .booking-user-details
    .user-details
    .user-title
    .user-avatar {
    width: 56px;
  }
  .booking-list-sec .booking-user-details .user-details .user-title .name {
    font-size: 22px;
    line-height: 1;
  }
  .booking-list-sec .booking-user-details .user-details .email-address .email {
    font-size: 15px;
  }
  .add-modal-width {
    width: 85% !important;
  }
  .dashboardrecentbooking {
    width: 58%;
  }
  .active-driver-width {
    width: 40%;
  }
}

@media screen and (max-width: 990px) {
  .driver-detail-wrapper {
    display: block;
    gap: 30px;
  }
  .driver-avatar {
    width: 100%;
  }
  .verify-portion-wrapper {
    width: 100%;
    margin: 60px 0 0;
  }
  .avatar-detail {
    width: 100%;
  }
  .info-display {
    column-count: 1;
  }
  .verify-card {
    flex-wrap: wrap;
    gap: 10px;
  }
  .driver-details-wrapper .ant-card-body {
    padding: 10px;
  }
  .ant-card-body .css-zhbgj8 {
    flex-wrap: wrap;
  }

  .driverdetailtabpart .ant-card-body {
    padding: 20px 5px;
  }
  .invoice-user-details {
    width: 100%;
  }
  .invoice-payment-details {
    width: 100%;
  }
  .booking-list-sec {
    display: block;
  }
  .booking-list-sec .booking-users {
    width: 100%;
  }
  .booking-list-sec .booking-user-details {
    width: 96%;
    position: absolute;
    top: 72px;
    right: 50%;
    transform: translatex(50%);
  }
  /* .booking-list-sec .booking-users .user-list .user-img-width {
    width: 8%;
  } */
  .booking-list-sec .booking-users .user-list .user-active {
    width: 15%;
  }
  /* .booking-list-sec .booking-users .user-list .username {
    width: 72%;
  } */
  .booking-list-sec .booking-user-details .details-header .icons {
    display: block;
  }
  .booking-list-sec .booking-user-details .details-header {
    padding-bottom: 0;
  }
  .justify-centent-end {
    justify-content: end;
  }

  .text-align-duration-end {
    text-align: end !important;
  }
  .vehicle-gallery-sec {
    flex-direction: column;
    gap: 0;
  }
  .vehicle-reg-gallery {
    width: 100%;
  }
  .edit-profile-row {
    flex-direction: column;
  }
  .width-65-card {
    width: 100%;
  }
  .width-35-card {
    width: 100%;
  }
  .dashboard-box-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
  }
  .dashboard-box {
    grid-template-columns: repeat(3, 1fr);
  }
  .barchart {
    display: flex;
  }
  .active-user-chart-wrapper {
    width: 100% !important;
  }
  .column-chart-wrapper {
    width: 100% !important;
  }
  .dashboardrecentbooking {
    width: 100%;
  }
  .active-driver-width {
    width: 100%;
    margin-top: 20px;
  }
  .width-60-skeleton {
    width: 100%;
}
.width-40-skeleton {
  width: 100%;
}
.details-header .status{width: auto!important; gap: 10px;}
.ml-auto-percent{margin-left: 70%;}
}

@media screen and (max-width: 600px) {
  .barchart {
    display: block;
  }
  .user-section .user-description {
    display: block;
  }
  .order-details-body .details {
    width: 100%;
  }
  .dashed {
    border-right: 0px solid #bdbdbd;
  }
  .trip-card .ant-row {
    flex-flow: wrap;
  }
  .invoice-user-details {
    width: 100%;
  }
  .invoice-payment-details {
    width: 100%;
  }
  .transection-logo {
    width: 180px;
  }
  .width-75 {
    width: 100%;
  }
  .width-25 {
    width: 100%;
  }
  .width-50 {
    width: 100%;
  }
  .border-left {
    padding-top: 20px;
  }
  .row-reverse {
    flex-direction: column;
  }
  .flexwrap {
    flex-wrap: wrap;
    gap: 0 !important;
  }
  .add-form .form-list {
    flex-wrap: wrap;
    padding: 10px 0px !important;
  }
  .form-list {
    flex-wrap: wrap;
    padding: 10px 20px !important;
  }

  .driverinfotab {
    flex-direction: column;
  }
  .drivertabwrapper {
    margin-top: 20px;
    padding-left: 0px;
  }
  .driverinfotab .border-right {
    border-right: 0;
  }
  .licensegallery .license-gallery-img {
    width:100%;
  }
  .licensegallery .license-gallery-img .ant-card-head {
    font-size: 16px;
  }
  .tlclicensetab .ant-card {
    margin: 0 !important;
  }
  .tlcinput {
    display: block;
  }
  .tlcinput .form-group {
    width: 100%;
  }
  .insurancetabwrapper {
    display: block;
  }
  .insurancetabwrapper .form-group {
    width: 100%;
  }
  .insurancegallery .insuracefront {
    width: 100%;
  }
  .drugtest {
    margin: 0;
  }
  .drugtest .drugtest-form {
    width: 100%;
  }
  .drug-gallery {
    width: 100%;
  }
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .vehicle-info {
    margin: 0px;
    padding: 0 !important;
  }
  .vehicle-body {
    padding: 0 !important;
  }
  .width-full {
    width: 100% !important;
  }
  .width-full .ant-select-selector {
    width: 100% !important;
  }
  .passenger-detail-title {
    width: 100%;
    display: block;
  }
  .edit-profile-btn {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 30px;
  }
  .passenger-info-wraper {
    padding-top: 35px;
  }
  .transection-id {
    font-size: 20px;
  }
  .enquiy-detail-top-part {
    display: block;
  }
  .enquiry-chatbox .chatbox-body {
    padding: 20px 10px;
  }
  .enquiry-chatbox .chatbox-body .chat-box-left {
    width: 80%;
  }
  .enquiry-chatbox .chatbox-body .chat-box-right .chat-right-time {
    width: 80%;
  }
  .chatbox-footer {
    padding: 10px 20px;
  }
  .social-icon-function {
    flex-direction: column;
  }
  .pt-pb-0 {
    padding: 0 18px !important;
  }
  .padding-m-0 {
    padding: 0 !important;
  }
  .padding-m-0 .ant-card-body {
    padding: 10px !important;
  }
  /* .ant-card.boxes {
  width: 324px;
} */
  .border-left {
    border: 0 !important;
  }
  .ant-card.boxes {
    width: 100%;
    margin-top: 12px;
  }
  .dashboard-box {
    grid-template-columns: repeat(1, 1fr);
  }
  /* .passenger-avatar{width:100%;} */
  .booking {
    width: 330px;
    left: 5px;
  }
  .btn-gap {
    gap: 8px;
  }
  .user-description .user-details-sec {
    width: 100%;
  }
  /* .booking-list-sec .booking-users .user-list .user-img-width {
    width: 20%;
  } */
  .booking-list-sec .booking-user-details .user-details .user-title {
    width: 100%;
  }
  .booking-list-sec .booking-user-details .user-details {
    flex-wrap: wrap;
    gap: 20px;
  }
  .order-details-body .trip-card .trip-card-picup {
    border: 0;
  }
  .booking-list-sec .booking-users .user-list .user-active {
    width: 20%;
  }
  .booking-list-sec .booking-user-details .user-details .user-title .name {
    font-size: 18px;
  }
  .booking-list-sec
    .booking-user-details
    .user-details
    .user-title
    .user-avatar {
    width: 52px;
  }
  .responsive-button .button-label {
    display: none;
  }
  .margin-r-0 .ant-btn-icon {
    margin-right: 0 !important;
  }
  .user-footer {
    flex-wrap: wrap;
  }
  .user-footer .user-pagination {
    margin-top: 10px;
  }
  .css-vmja4v-Footer {
    height: 100px;
  }
  .img-border-right {
    border: 0;
    padding-right: 0;
  }
  .display-block-m {
    display: block !important;
  }
  .order-details-body-2 {
    gap: 26px;
    flex-direction: column;
  }
  .user-section-2 {
    width: 100%;
  }
  .map-section-2 {
    width: 100%;
    height: 250px;
  }
  .order-details-body-2 {
    gap: 20px;
  }
  .user-section-2 .user-details-2 {
    padding: 10px;
  }
  .admin-form-sec {
    margin-left: 0px;
  }
  .flex-col {
    flex-direction: column !important;
  }
  .modal-content-width-30 {
    width: 100%;
  }
  .modal-content-width-70 {
    width: 100%;
  }
  .edit-profile-row .rofile-info .ant-card-body {
    flex-direction: column;
  }
  .width-30per {
    width: 100%;
  }
  .width-70per {
    width: 100%;
  }
  .mobile-mb-0 {
    margin-bottom: 0 !important;
  }
  .mobile-flex-wrap {
    flex-wrap: wrap;
  }
  .border-right {
    border: 0 !important;
  }
  .invoice-payment-details .ant-card-body {
    flex-direction: column;
  }
  .invoice-payment-details .ant-card-body .distance-sec {
    width: 100%;
  }

  .invoice-payment-details-invoice .ant-card-body {
    flex-direction: column;
  }
  .invoice-payment-details-invoice .ant-card-body .distance-sec {
    width: 100%;
  }
  .barchart {
    display: block;
  }
  .dashboard-box-2 {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
  .dashboard-pie-chart .apexcharts-legend {
    top: -8px !important;
  }
  .active-user-chart-wrapper .ant-card-body .apexcharts-canvas {
    width: auto !important;
  }
  .dashboard-box-2 { gap: 12px;}
  /* .dashboard-pie-chart{width: 450px!important;} */
  .notification-heading{font-size: 20px;}
  .notification-innerpart .ant-card-body{flex-direction: column;}
  .notification-title-name{flex-direction: column; align-items: start; gap: 10px;}
  .booking-list-sec .booking-users .user-header{flex-direction: column; gap: 10px;}
  .avatar-details-loader{flex-direction: column;}
  .info-display-skeleton {column-count: 1; padding-top: 20px;}
  .enquiry-content-part{flex-direction: column; align-items: start; border-right: 0;}
  .ml-auto-percent{margin-left: 41%;}
  .modal-header-bg{font-size: 25px;}
  .avatar-detail{flex-direction: column;}
  .user-footer{padding-top: 8px;}
  .user-list-height{
      max-height: 763px;
    min-height: 763px;
  }
  .order-details-body-2 .details-text{padding-left: 20px;}
}

@media screen and (max-width: 480px) {
  .user-section .user-description {
    display: block;
  }
  .order-details-body .details {
    width: 100%;
  }
  .dashed {
    border-right: 0px solid #bdbdbd;
  }
  .trip-card .ant-row {
    flex-flow: wrap;
  }
  .invoice-user-details {
    width: 100%;
  }
  .invoice-payment-details {
    width: 100%;
  }
}
